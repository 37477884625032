import { render, staticRenderFns } from "./version-update.vue?vue&type=template&id=65685a23&scoped=true&"
import script from "./version-update.vue?vue&type=script&lang=js&"
export * from "./version-update.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65685a23",
  null
  
)

export default component.exports