<!--
	@name app-source-result-dialog-websocket-error
	@description Dialog component for displaying websocket error
	@date 2022/03/01
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :show-close="false" persistent>
		<template #header>
			{{ title }}
		</template>

		<template #body>
			<div>
				<p v-if="reconnectAttempt === maxReconnectAttempts && !reconnectCountdown" class="text-body-1 mb-0 error--text">
					<span class="font-weight-bold">We have been unable to re-establish a connection to our communications server.</span>
					This is usually related to a temporary loss of internet connectivity in your web browser. To attempt to re-establish the connection manually, click
					<span class="font-weight-bold">retry</span>. To speak with a member of our support team, click <span class="font-weight-bold">live chat</span>.
				</p>
				<p v-else class="text-body-1 mb-0">
					We have temporarily lost the connection with our communications server. Please bear with us as we attempt to re-establish the connection.
				</p>
			</div>
		</template>

		<template #footer>
			<div class="py-4 px-5 d-flex">
				<p v-if="reconnectCountdown" class="text-body-2 mb-0 info--text">
					<span class="font-weight-medium">Attempt {{ reconnectAttempt }} of {{ maxReconnectAttempts }}. </span>
					<span class="font-italic">Reconnecting in {{ reconnectCountdownDisplay }}</span>
				</p>
				<v-fade-transition hide-on-leave>
					<div v-if="reconnectAttempt === maxReconnectAttempts && !reconnectCountdown">
						<v-btn color="primary" class="mr-4" @click="reconnect">
							Retry
						</v-btn>
						<v-btn color="secondary" @click="openChat">
							live chat
						</v-btn>
					</div>
				</v-fade-transition>
				<p v-if="!reconnectCountdown && reconnectAttempt !== maxReconnectAttempts" class="text-body-2 mb-0 info--text">
					<span class="font-italic">Reconnecting...</span>
				</p>
			</div>
		</template>
	</common-dialog>
</template>

<script>
	import CommonDialog from '@/component/common/dialog';
	import { EventBus, GoogleAnalytics } from '@/utils';

	export default {
		name: 'app-source-result-dialog-websocket-error',

		components: { CommonDialog },

		data() {
			return {
				options: {
					type: null,
					openDialogOnAttempt: 1
				},
				title: 'Error',
				reconnectCountdown: null,
				reconnectAttempt: null,
				maxReconnectAttempts: null,
				countdownInterval: null
			};
		},

		computed: {
			reconnectCountdownDisplay() {
				let seconds = this.reconnectCountdown >= 10 ? `${this.reconnectCountdown}` : `0${this.reconnectCountdown}`;
				return `00:${seconds}`;
			}
		},

		created() {
			this.$websocket.$on('reconnect', this.onWebSocketReconnect);
		},

		methods: {
			/**
			 * @name options
			 * @description Open the dialog
			 */
			init(options) {
				this.options = { ...this.options, ...options };

				// trigger reconnect on open if not already attempting
				if (!this.reconnectAttempt && options.type === 'error') this.reconnect();

				this.title = options.type === 'error' ? 'Connection Error' : 'Disconnection Error';
			},

			close() {
				this.$refs.dialog.close();
			},

			/**
			 * @name retryWebSocket
			 * @description Retry the websocket connection
			 */
			retryWebSocket() {
				this.$emit('retry-websocket');
			},

			/**
			 * @name switchToStandardServer
			 * @description Switch to the standard server
			 */
			switchToStandardServer() {
				this.$emit('switch-to-standard-server');
			},

			onWebSocketReconnect({ timeout, attempt, maxAttempts }) {
				this.reconnectCountdown = timeout;
				this.reconnectAttempt = attempt;
				this.maxReconnectAttempts = maxAttempts;

				// Clear any existing countdown
				if (this.countdownInterval) {
					clearInterval(this.countdownInterval);
				}

				if (this.reconnectAttempt >= this.options.openDialogOnAttempt) {
					this.$refs.dialog.open();

					GoogleAnalytics.sendEvent('socketError', {
						'gtm.element.dataset.gaId': 'results',
						'gtm.element.dataset.gaValue': `${this.options.type} attempt ${this.reconnectAttempt}: message displayed`
					});
				}

				// Start the countdown
				this.countdownInterval = setInterval(() => {
					this.reconnectCountdown--;
					if (this.reconnectCountdown <= 0) {
						clearInterval(this.countdownInterval);
					}
				}, 1000);
			},

			reconnect() {
				this.$websocket.$retry(true);
			},

			/**
			 * @name openChat
			 */
			openChat() {
				EventBus.$emit('open-chat');
			}
		}
	};
</script>
