<!--
	@name common-dialog-version-update
	@description Version Update Dialog
	@date 2024/01/01
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name" persistent :show-close="false" :max-width="550">
		<template #header>
			<span>System Update Required</span>
		</template>
		<template #body>
			<div>
				<p class="text-body-1">
					A new version of the system is available (<span class="font-weight-bold">v{{ versionCheck }}</span>). You are currently using version <span class="font-weight-bold">v{{ versionCurrent }}</span>. Please click the button below to update to the latest version.
				</p>
				<v-btn color="primary" class="mt-2" @click="update">
					{{ timer > 1 ? `Update to latest version (${timer}s)` : 'Updating...' }}
				</v-btn>
			</div>
		</template>
	</common-dialog>
</template>

<script>
	import CommonDialog from '@/component/common/dialog';
	import { mapState } from 'vuex';
	import { GoogleAnalytics } from '@/utils';

	export default {
		name: 'common-dialog-version-update',

		components: { CommonDialog },

		data() {
			return {
				timer: 5,
				interval: null,
				targetRoute: null
			};
		},

		computed: {
			...mapState('SystemVersion', ['versionCurrent', 'versionCheck'])
		},

		methods: {
			open(targetRoute) {
				this.targetRoute = targetRoute;
				this.$refs.dialog.open();
				this.startTimer();

				GoogleAnalytics.sendEvent('systemUpdatePromptOpened', {
					'gtm.element.dataset.gaId': this.$route.name
				});
			},

			startTimer() {
				this.timer = 5;
				this.interval = setInterval(() => {
					if (this.timer > 0) {
						this.timer--;
					} else {
						clearInterval(this.interval);
						this.update();
					}
				}, 1000);
			},

			update() {
				if (this.interval) {
					clearInterval(this.interval);
				}
				// force cache clear and reload
				window.caches
					.keys()
					.then((names) => names.map((name) => caches.delete(name)))
					.then(() => {
						if (this.targetRoute) {
							window.location.assign(this.$router.resolve(this.targetRoute).href);
						} else {
							window.location.reload(true);
						}
					});
			}
		}
	};
</script>

<style lang="scss" scoped>
</style>
